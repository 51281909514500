@import '../../variables';

@header-sm-height: 32px;
.headerAction {
  float: right;
  margin-left: 16px;
  color: rgba(0,0,0,.65);
  border-color: #d9d9d9;
  margin-right: 40px;
  transition: none;
  height: @header-sm-height * 2;
  :global .headerActionItem {
    display: inline-block;
    border: 0;
    height: 100%;
    padding: 0 12px;
    cursor: pointer;
    transition: all .3s;
    &:hover {
      background: @content-bg;
    }
  }
}

@primary-color: #7546c9;